import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

function PortfolioIllustration() {
  let navigate = useNavigate();
  const [otherInfo, setOtherInfo] = useState({
    client: "",
    date: new Date().toISOString().slice(0, 10),
    equity: "",
    debt: "",
    total: "",
    ltv: "",
  });

  const [tableRows, setTableRows] = useState(() => {
    const initialRows = [...Array(1)].map(() => ({
      id: "",
      equity: "",
      debt: "",
      yield: "",
      annualizedDistribution: "",
      monthlyDistribution: "",
      sector: "",
      state: "",
      unit: "",
      ltv: "",
    }));
    return initialRows;
  });
  const [dsts, setDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [equityDifference, setEquityDifference] = useState(0);

  useEffect(() => {
    const totalEquity = otherInfo?.equity?.replace(/[^0-9.]/g, "");
    const sumEquity = tableRows?.reduce((acc, row) => {
      const rowEquity =
        row?.equity?.trim() === ""
          ? 0
          : parseFloat(row?.equity?.replace(/[^0-9.]/g, ""));
      return acc + rowEquity;
    }, 0);
    setEquityDifference(Math.round(totalEquity - sumEquity));
  }, [otherInfo, tableRows]);

  useEffect(() => {
    getIdData();
  }, []);

  const getIdData = async () => {
    try {
      setLoading(true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      // Get user role and email
      const userRole = localStorage.getItem("userRole");
      const userEmail = localStorage.getItem("userEmail");

      // Fetch user's assigned DSTs
      const userRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      const currentUser = userRes.Items.find(user => user.userEmail === userEmail);

      // Get all DSTs assigned to any of user's broker dealers
      let allAssignedDsts = [];
      if (currentUser?.brokerDealerIds?.length > 0) {
        // Get all users that share any of the current user's broker dealers
        const relatedUsers = userRes.Items.filter(user =>
          user.brokerDealerIds?.some(id =>
            currentUser.brokerDealerIds.includes(id)
          )
        );

        // Combine all assigned DSTs from related users
        allAssignedDsts = [...new Set(
          relatedUsers.flatMap(user => user.assignedDsts || [])
        )];
      }

      let filteredDsts = res;

      // Show filtered DSTs only if:
      // 1. User is not Super Admin
      // 2. User has broker dealers with assigned DSTs
      if (userRole !== "Super Admin" && allAssignedDsts.length > 0) {
        filteredDsts = res.filter(dst => allAssignedDsts.includes(dst.id));
      }
      // If no DSTs are assigned or user is Super Admin, show all DSTs

      // Filter active DSTs
      const activeDsts = filteredDsts.filter((item) => item.is_active !== false);

      // Sort by DST name
      const sortedDsts = activeDsts.sort((a, b) =>
        a?.basicInfo?.fullLegalNameOfDst?.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );

      setDsts(sortedDsts);

    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeOtherInfo = (e) => {
    const updatedOtherInfo = { ...otherInfo, [e.target.name]: e.target.value };
    setOtherInfo(updatedOtherInfo);
    saveStateToSessionStorage(updatedOtherInfo);
  };

  const applyMask = (inputVal) => {
    const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput.split(".");
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };

  const handleTotalChange = (event) => {
    const { name, value } = event.target;
    const inputVal = event.target.value;
    const maskedInput = applyMask(inputVal);
    const updatedOtherInfo = {
      ...otherInfo,
      [name]: maskedInput,
    };

    const cleanedEquity = updatedOtherInfo.equity.replace(/[^0-9.]/g, "");
    const cleanedDebt = updatedOtherInfo.debt.replace(/[^0-9.]/g, "");

    if (cleanedEquity !== "" && cleanedDebt !== "") {
      const total = parseFloat(cleanedEquity) + parseFloat(cleanedDebt);
      updatedOtherInfo.total = total.toLocaleString();
      updatedOtherInfo.ltv =
        ((parseFloat(cleanedDebt) / total) * 100).toFixed(2) + "%";
    } else {
      updatedOtherInfo.total = "";
      updatedOtherInfo.ltv = "";
    }

    setOtherInfo(updatedOtherInfo);
    saveStateToSessionStorage(updatedOtherInfo);
  };

  const handleDropdownChange = (event, index) => {
    const selectedId = event.target.value;
    const selectedDstItem = dsts.find((item) => item.id === selectedId);
    setSelectedItem((prevItems) => {
      const updatedItems = [...(prevItems || [])];
      updatedItems[index] = selectedDstItem;
      return updatedItems;
    });
    const updatedTableRows = tableRows.map((row, i) => {
      if (i === index) {
        const cleanedDebt = selectedDstItem?.basicInfo?.offeringDebt.replace(
          /[^0-9.]/g,
          ""
        );
        const cleanedEquity =
          selectedDstItem?.basicInfo?.offeringEquity.replace(/[^0-9.]/g, "");


        return {
          id: selectedId,
          name: selectedDstItem?.basicInfo?.fullLegalNameOfDst,
          equity: "",
          debt: "",
          total: "",
          yield: selectedDstItem?.yields?.year1Yield.replace("%", ""),
          annualizedDistribution: "",
          monthlyDistribution: "",
          sector: selectedDstItem?.basicInfo?.dstPropertiesSector,
          state: selectedDstItem?.basicInfo?.states || "N/A",
          unit: selectedDstItem?.basicInfo?.totalUnitsInPortfolio || 0,
          ltv: selectedDstItem
            ? ((+cleanedDebt / (+cleanedDebt + +cleanedEquity)) * 100).toFixed(
              2
            ) || 0
            : null,
        };
      } else {
        return row;
      }
    });
    saveStateToSessionStorage(otherInfo, updatedTableRows, selectedItem);
    setTableRows(updatedTableRows);
    setErrMessage(false);
  };

  const handleEquityChange = (event, index) => {
    const newEquityValue = event.target.value;
    const maskedInput = applyMask(newEquityValue);
    const cleanedEquity = maskedInput.replace(/[^0-9.]/g, "");

    const selectedItems = selectedItem[index];

    if (selectedItems) {
      const cleanedDebt = selectedItems?.basicInfo?.offeringDebt.replace(
        /[^0-9.]/g,
        ""
      );
      const cleanedEquityForm =
        selectedItems?.basicInfo?.offeringEquity.replace(/[^0-9.]/g, "");

      const y = cleanedEquity / cleanedEquityForm;
      const debt = Number((y * cleanedDebt).toFixed(2)).toLocaleString("en-US");
      const cleanedDebtCalc = debt.replace(/[^0-9.]/g, "");

      const currentYield = parseFloat(selectedItems?.yields?.year1Yield.replace("%", "") || 0);
      const annualized = ((cleanedEquity * currentYield) / 100).toFixed(0); // Annualized $ = equity * yield%
      const monthly = (annualized / 12).toFixed(0); // Monthly $ = Annualized $ / 12

      setTableRows((prevRows) =>
        prevRows.map((row, i) => {
          if (i === index) {
            return {
              ...row,
              equity: maskedInput,
              debt: debt,
              total: (+cleanedEquity + +cleanedDebtCalc).toLocaleString(),
              annualizedDistribution: Number(annualized).toLocaleString(),
              monthlyDistribution: Number(monthly).toLocaleString()
            };
          } else {
            return row;
          }
        })
      );

      saveStateToSessionStorage(otherInfo, tableRows, selectedItem);
    }
  };

  useEffect(() => {
    const hasEmptyEquityValue = tableRows.some((row) => row.equity === "");
    setShowAddButton(tableRows.length < 200 && !hasEmptyEquityValue);
  }, [tableRows]);

  const handleAddRow = () => {
    setShowAddButton(false);
    setTableRows((prevRows) => [
      ...prevRows,
      {
        id: "",
        debt: "",
        yield: "",
        sector: "",
        state: "",
        unit: "",
        ltv: "",
        equity: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    setTableRows((prevRows) => {
      if (prevRows.length > 1) {
        const updatedRows = prevRows.filter((_, i) => i !== index);
        const updatedRowsWithTotal = updatedRows.map((row) => {
          return {
            ...row,
            total: (row.equity || 0) + (row.debt || 0) === 0 ? "" : row.total,
          };
        });
        return updatedRowsWithTotal;
      }
      return prevRows;
    });
  };

  useEffect(() => {
    const storedState = JSON.parse(sessionStorage.getItem("portfolioState"));
    if (storedState) {
      setTableRows(storedState.tableRows);
      setOtherInfo(storedState.otherInfo);
      if (storedState.selectedItem) {
        setSelectedItem(storedState.selectedItem);
      }
    }
  }, []);

  const handleAddData = () => {
    const rowsWithNonEmptyIds = tableRows.filter((row) => row.id !== "");
    if (rowsWithNonEmptyIds.length > 0) {
      sessionStorage.setItem(
        "portfolioState",
        JSON.stringify({
          tableRows: rowsWithNonEmptyIds,
          otherInfo,
          selectedItem,
        })
      );
      navigate("/portfolio-illustration-report", {
        state: {
          tableRows: rowsWithNonEmptyIds,
          otherInfo,
        },
      });
    } else {
      setErrMessage(true);
    }
  };

  const handleClearField = (index) => {
    const emptyRow = {
      id: "",
      equity: "",
      debt: "",
      yield: "",
      total: "",
      sector: "",
      state: "",
      unit: "",
      ltv: "",
    };
    const updatedTableRows = tableRows.map((row, i) =>
      i === index ? emptyRow : row
    );
    setTableRows(updatedTableRows);
  };
  const cleanNumeric = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  };
  const columnTotals = {
    equity: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.equity), 0)
    ),
    debt: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
    ),
    total: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
    ),
    ltv: +(
      (cleanNumeric(
        tableRows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
      ) /
        cleanNumeric(
          tableRows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
        )) *
      100
    ).toFixed(2),
    yield: tableRows
      ?.reduce((total, row) => {
        return total + cleanNumeric(row.equity) * cleanNumeric(row.yield);
      }, 0)
      .toFixed(2),
    unit: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.unit), 0)
    ),
    annualized: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.annualizedDistribution), 0)
    ),

    monthly: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.monthlyDistribution), 0)
    ),
  };


  const totalRow = {
    name: "Portfolio Results (Weighted):",
    equity: +columnTotals.equity ? columnTotals.equity : 0,
    debt: +columnTotals.debt,
    total: +columnTotals.total,
    ltv: +columnTotals.ltv,
    yield:
      +columnTotals.yield / +columnTotals.equity
        ? (+columnTotals.yield / +columnTotals.equity).toFixed(2)
        : 0,
    unit: +columnTotals.unit,
    annualized: +columnTotals.annualized.toFixed(0), // New property
    monthly: +columnTotals.monthly.toFixed(0),       // New property
  };

  const handleSessionClear = () => {
    sessionStorage.clear("portfolioState");
    setTableRows([]);
    setOtherInfo({
      client: "",
      date: new Date().toISOString().slice(0, 10),
      equity: "",
      debt: "",
      total: "",
      ltv: "",
    });
  };

  const saveStateToSessionStorage = (
    updatedOtherInfo,
    updatedTableRows,
    updatedSelectedItem
  ) => {
    sessionStorage.setItem(
      "portfolioState",
      JSON.stringify({
        otherInfo: updatedOtherInfo || otherInfo,
        tableRows: updatedTableRows || tableRows,
        selectedItem: updatedSelectedItem || selectedItem,
      })
    );
  };

  return (
    <>
      <div style={{ margin: "2rem" }}>
        <Grid container spacing={2}>
          <Card
            style={{
              width: "100%",
              minHeight: "85vh",
              marginTop: "1rem",
              marginLeft: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <h4 style={{ margin: "1rem 2rem" }}>PORTFOLIO ILLUSTRATION</h4>
              <div
                style={{
                  margin: "0rem 2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                  }}
                >
                  <Button
                    onClick={handleAddData}
                    type="submit"
                    variant="contained"
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    View Report
                  </Button>
                  <Button
                    onClick={handleSessionClear}
                    type="submit"
                    variant="contained"
                    style={{
                      marginLeft: "0.6rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    Reset
                  </Button>
                </div>
                {errMessage ? (
                  <Alert severity="error">
                    Atleast one of the DST should be selected!
                  </Alert>
                ) : null}
              </div>
            </div>
            <div style={{ margin: "0rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <h5>Prepared For</h5>
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="client"
                    label="Prepared For"
                    value={otherInfo.client}
                    onChange={handleChangeOtherInfo}
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="date"
                    type="date"
                    value={otherInfo.date}
                    onChange={(e) =>
                      setOtherInfo({
                        ...otherInfo,
                        date: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Date"
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "1rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <h5>Client Investment</h5>
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Equity"
                    name="equity"
                    value={otherInfo.equity}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Debt"
                    name="debt"
                    value={otherInfo.debt}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Total"
                    name="total"
                    value={otherInfo.total}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="LTV"
                    name="ltv"
                    value={otherInfo.ltv}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "0rem 0rem" }}>
              <Grid>
                <div
                  style={{
                    margin: "0rem 0rem",
                    marginTop: "4rem",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  <Grid>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      {showAddButton ? (
                        <Button
                          onClick={handleAddRow}
                          type="submit"
                          variant="contained"
                          style={{
                            margin: "0rem 0rem",
                            padding: "0.1rem 0.5rem",
                            marginBottom: "0.5rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          Add DST
                        </Button>
                      ) : null}
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Proposed Portfolio
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Equity
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Debt
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            LTV
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Sector
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Current Distribution
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                            Distribution Annualized
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                            Distribution Monthly
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Units
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            States
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRows?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                padding: "6px",
                                width: "17%",
                              }}
                            >
                              <div>
                                <TextField
                                  select
                                  size="small"
                                  margin="normal"
                                  fullWidth
                                  InputProps={{
                                    style: { width: "15rem" },
                                    endAdornment: row.id ? (
                                      <IconButton
                                        aria-label="clear"
                                        size="small"
                                        onClick={() => handleClearField(index)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ) : null,
                                  }}
                                  style={{ margin: "0px" }}
                                  label="Select DST"
                                  value={row.id}
                                  onChange={(event) =>
                                    handleDropdownChange(event, index)
                                  }
                                  SelectProps={{
                                    IconComponent: row.id
                                      ? () => null
                                      : undefined,
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300,
                                        },
                                      },
                                    },
                                  }}
                                >
                                  {loading ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                    </Box>
                                  ) : (
                                    dsts?.map((option, ind) => (
                                      <MenuItem key={ind} value={option.id}>
                                        {option.basicInfo.fullLegalNameOfDst}
                                      </MenuItem>
                                    ))
                                  )}
                                </TextField>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                {row.id ? (
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={row.equity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    value={row.equity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                { }
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.debt}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.total}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.ltv}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.sector}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yield}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row?.annualizedDistribution}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row?.monthlyDistribution}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.unit}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.state}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                borderBottom: "none",
                                padding: "6px",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                disabled={index > 0 ? false : true}
                                size="large"
                                color="#fff"
                                onClick={() => handleRemoveRow(index)}
                              >
                                <DeleteIcon style={{ color: "#ff5555" }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "start",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRow.name}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.equity
                              ? Math.round(totalRow.equity).toLocaleString(
                                "en-US"
                              )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.debt
                              ? Math.round(totalRow.debt).toLocaleString(
                                "en-US"
                              )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.total
                              ? Math.round(totalRow.total).toLocaleString(
                                "en-US"
                              )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.ltv ? `${totalRow.ltv}%` : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.yield ? `${totalRow.yield}%` : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${totalRow.annualized ? totalRow.annualized.toLocaleString("en-US") : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${totalRow.monthly ? (totalRow.monthly).toLocaleString("en-US") : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.unit ? totalRow.unit : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "start",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b> Equity Difference:</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {equityDifference.toLocaleString("en-US")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </div>
              </Grid>
            </div>
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default PortfolioIllustration;
